import { CommonModule } from '@angular/common';
import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component({
  selector: 'app-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './popup.component.html',
  styleUrl: './popup.component.scss'
})
export class PopupComponent {
  @Input() message: string = '';
  @Output() closePopup = new EventEmitter<void>();
  visible: boolean = true;

  close() {
    this.visible = false;
    this.closePopup.emit();
  }
}
